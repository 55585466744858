<router-outlet></router-outlet>
<app-loader></app-loader>
<div style="display: none" class="preload-img">
  <img src="assets/icon/warning.svg" alt="" />
  <img src="assets/icon/success.svg" alt="" />
  <img src="assets/icon/error.svg" alt="" />
  <img src="assets/icon/info.svg" alt="" />
</div>

<tui-image-editor-svg-definitions></tui-image-editor-svg-definitions>
