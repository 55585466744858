import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  CountNotification$ = new BehaviorSubject(0);
  msg$ = new BehaviorSubject([]);
  debugCountNotification$ = true;

  constructor(public api: ApiService) {
    this.getNotification();

    if (environment.production || this.debugCountNotification$) {
      this.getNotification();
      // eslint-disable-next-line angular/interval-service
      setInterval(x => {
        this.getNotification();
      }, 15000);
    }
  }

  getNotification() {
    if (+this.api.user?.StaffId > 0) {
      this.api.GetNotification(this.api.user.StaffId).subscribe(res => {
        if (res.successful) {
          var CountNotification = res.Count[0].CountNotification;
          let msg1 = res.msg1;
          let msg2 = res.msg2;
          let msg = [...msg1, ...msg2];
          this.CountNotification$.next(CountNotification);
          this.msg$.next(msg);
        }
      });
    }
  }
}
