import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Observable, of} from 'rxjs';
import {LoggerService} from './logger.service';
import {ClaimModel, DataCarImg, UserProfile} from '../model/model';
import {AuthService} from './auth.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {base64ToFile} from '../../image-cropper/utils/blob.utils';
import {environment} from '../../../environments/environment';
import {HttpBackend, HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

const logger = new LoggerService('ApiService');

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  API_SERVER_ADDRESS1 = environment.API_SERVER_ADDRESS1;
  private httpBackend: HttpClient;

  constructor(
    public http: HttpService,
    public auth: AuthService,
    public sanitizer: DomSanitizer,
    handler: HttpBackend
  ) {
    this.httpBackend = new HttpClient(handler);
  }

  getSafeUrl(imgDataURL = '') {
    return this.sanitizer.bypassSecurityTrustUrl(imgDataURL);
  }

  toObjectURL(image): SafeUrl {
    const blob = base64ToFile(image);
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
  }

  blobToBase64(blob): Promise<any> {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }

  async getBlob(url: string) {
    return await this.http.httpClient.get(url, {responseType: 'blob'}).toPromise();
  }

  async convertUrlToBase64(url: string) {
    let blob = await this.getBlob(url);
    return await this.blobToBase64(blob);
  }

  get user(): UserProfile {
    return this.auth.user;
  }

  GetNotification(StaffId): Observable<any> {
    return this.httpBackend.get(this.API_SERVER_ADDRESS1 + '/asset/Get-Notification', {params: {StaffId}});
  }

  Login(UserName: any, PassWord: any): Observable<any> {
    return this.http.post('Login', {UserName, PassWord});
  }

  Get_BrandCar(): Observable<any> {
    return this.http.get('Get_BrandCar');
  }

  Get_ModelsCar(BrandId): Observable<any> {
    return this.http.get('Get_ModelsCar', {BrandId});
  }

  Get_Province(): Observable<any> {
    return this.http.get('Get_Province');
  }

  Get_DocumentCliam1(searchData: any = {}): Observable<any> {
    return this.http.get('Get_DocumentCliam1', searchData);
  }

  GetDocNotificationById(searchData: any = {}): Observable<any> {
    return this.http.get('Get-DocNotificationById', searchData);
  }

  Get_HistoryCliam1(searchData: any = {}): Observable<any> {
    const StaffId = this.user.StaffId;
    return this.http.get('Get_HistoryCliam1', {...searchData, StaffId});
  }

  ClHistoryWorkAssign(searchData: any = {}): Observable<any> {
    return this.http.get('asset2/Cl-HistoryWorkAssign');
  }

  GET_Remark(ClaimId: any): Observable<any> {
    return this.http.get('GET_Remark', {ClaimId});
  }

  Update_StatusFlow(StatusFlowId: any = '', ContactId: any, StaffId: any = '', ClaimId: any = ''): Observable<any> {
    if (StatusFlowId) {
      StatusFlowId = '=' + StatusFlowId;
    }
    return this.http.post('Update_StatusFlow' + StatusFlowId, {ContactId, StaffId, ClaimId});
  }

  Get_PolicyData(CarRegisterNo: any): Observable<any> {
    return this.http.get('Get_PolicyData', {CarRegisterNo});
  }

  OneGetPolicyByContactId(ContactId = '', token = ''): Observable<any> {
    return this.http.get('One-GetPolicyByContactId', {ContactId, token});
  }

  GET_ShowRequestNo(ContactId: any): Observable<any> {
    return this.http.get('GET_ShowRequestNo', {ContactId});
  }

  GET_DetailForm(ClaimId: any): Observable<any> {
    return this.http.get('GET_DetailForm', {ClaimId});
  }

  Get_SignatureStaff(ClaimId: any): Observable<any> {
    return this.http.get('Get_SignatureStaff', {ClaimId});
  }

  Get_ActiveDataform(): Observable<any> {
    return this.http.get('Get_ActiveDataform');
  }

  Get_DetailImage(ImageCarId: any): Observable<any> {
    return this.http.get('Get_DetailImage', {ImageCarId});
  }

  Get_Repairpart(): Observable<any> {
    return this.http.get('Get_Repairpart', {});
  }

  ClGet_Repairpart(): Observable<any> {
    return this.http.get('Cl-Get_Repairpart', {});
  }

  Get_TypeCar(): Observable<any> {
    return this.http.get('Get_TypeCar', {});
  }

  Post_FormCliam(data: ClaimModel, Statussend: any = ''): Observable<any> {
    if (Statussend) {
      data.Statussend = Statussend;
    }
    return this.http.post('Post_FormCliam', data);
  }

  Update_EncypedClaimId(ClaimId: any): Observable<any> {
    return this.http.post('Update_EncypedClaimId', {ClaimId});
  }

  Post_PDFCliam(ClaimId: any, TypeformId: any, TypeAssetId: any, form_data: any): Observable<any> {
    return this.http.post('Post_PDFCliam', {ClaimId, TypeformId, TypeAssetId, form_data});
  }

  onePost_PDFClaim(data: any = {}): Observable<any> {
    return this.http.post('one-Post_PDFClaim', data);
  }

  onePost_Imagecar(token, ImageCarId, ClaimId): Observable<any> {
    return this.http.post('one-Post_Imagecar', {token, ImageCarId, ClaimId});
  }

  onePost_PDFClaimH(data: any = {}): Observable<any> {
    return this.http.post('asset2/one-Post_PDFClaimH', data);
  }

  onePost_ImagecarH(token, ImageCarId, ClaimId): Observable<any> {
    return this.http.post('asset2/one-Post_ImagecarH', {token, ImageCarId, ClaimId});
  }

  onePost_PDFCheck(data: any = {}): Observable<any> {
    return this.http.post('asset1/one-Post_PDFCheck', data);
  }

  onePost_ImagecarCheck(token, ImageCarId, ContactId): Observable<any> {
    return this.http.post('one-Post_ImagecarCheck', {token, ImageCarId, ContactId});
  }

  Get_PDFClaim(ClaimId: any): Observable<any> {
    return this.http.get('Get_PDFClaim', {ClaimId});
  }

  GenFormPDFClaim(form_data: any): Observable<any> {
    return this.http.post('GenFormPDF-Claim', {form_data});
  }

  AdminCl_GenFormPDF(form_data: any): Observable<any> {
    return this.http.post('Admin-Cl_GenFormPDF', {form_data});
  }

  Delete_ImageCar(ImageCarId: any): Observable<any> {
    return this.http.post('Delete_ImageCar', {ImageCarId});
  }

  Post_ImageCar(data: DataCarImg | FormData): Observable<any> {
    return this.http.post('Post_ImageCar', data);
  }

  GetTelContact(ContactId): Observable<any> {
    return this.http.get('GetTelContact', {ContactId});
  }

  GetSendLinkEdit(ContactId): Observable<any> {
    return this.http.get('Get-SendLinkEdit', {ContactId});
  }

  ChSendLinkEdit(ContactId): Observable<any> {
    return this.http.get('asset1/Ch-SendLinkEdit', {ContactId});
  }

  GET_StaffNumber(StaffId): Observable<any> {
    return this.http.get('GET_StaffNumber', {StaffId});
  }

  CheckTelForResetPassword(Tel): Observable<any> {
    return this.http.post(`CheckTelForResetPassword?Tel=${Tel}`);
  }

  CheckOTP(Tel, OTP): Observable<any> {
    return this.http.get(`CheckTelForResetPassword`, {Tel, OTP});
  }

  ResetPassword(data): Observable<any> {
    return this.http.post(`ResetPassword`, data);
  }

  GetTitlename(data: any = null): Observable<any> {
    return this.http.get('Get_Prefix');
  }

  GetProvince(data: any = null): Observable<any> {
    return this.http.get('Get_Province');
  }

  GetDistrict(ProvinceId: any): Observable<any> {
    if (!ProvinceId) {
      return of({data: []});
    }
    return this.http.get('Get_District', {ProvinceId});
  }

  GetSubdistrict(DistrictId: any): Observable<any> {
    if (!DistrictId) {
      return of({data: []});
    }
    return this.http.get('Get_Subdistrict', {DistrictId});
  }

  Get_Department(data: any = null): Observable<any> {
    return this.http.get('Get_Department');
  }

  Get_Position(data: any = null): Observable<any> {
    return this.http.get('Get_Position');
  }

  Get_Permission(data: any = null): Observable<any> {
    return this.http.get('Get_Permission');
  }

  Get_DataStaff(): Observable<any> {
    return this.http.get('Get_DataStaff');
  }

  Get_StaffA(): Observable<any> {
    return this.http.get('Get_StaffA');
  }

  Get_StaffB(): Observable<any> {
    return this.http.get('Get_StaffB');
  }

  Get_StaffAdmin(): Observable<any> {
    return this.http.get('Get_StaffAdmin');
  }

  Get_StaffAssign(): Observable<any> {
    return this.http.get('Get_StaffAssign');
  }

  Admin_SearchStaff(searchText: any) {
    return this.http.get('Admin_SearchStaff', {Name: searchText, StaffNumber: searchText});
  }

  Admin_DeleteStaff(StaffId: any): Observable<any> {
    return this.http.put('Admin_DeleteStaff', {StaffId});
  }

  AdminShowStaff(StaffId: any): Observable<any> {
    return this.http.post('Admin-ShowStaff', {StaffId});
  }

  AdminHideStaff(StaffId: any): Observable<any> {
    return this.http.post('Admin-HideStaff', {StaffId});
  }

  Get_showDataStaff(StaffId: any) {
    return this.http.get('Get_showDataStaff', {StaffId});
  }

  Get_DetailDataStaff(StaffId: any) {
    return this.http.get('Get_DetailDataStaff', {StaffId});
  }

  Get_Resarea(StaffId: any) {
    return this.http.get('Get_Resarea', {StaffId});
  }

  Admin_UpdateDatastaff(data: any) {
    return this.http.post('Admin_UpdateDatastaff', data);
  }

  Post_RegisterStaff(data: any) {
    return this.http.post('Post_RegisterStaff', data);
  }

  Admin_Postresarea(StaffId, ProvinceId, DistrictId) {
    return this.http.post('Admin-Postresarea', {StaffId, ProvinceId, DistrictId});
  }

  Admin_Getlistform(TypeformId, searchData = {}): Observable<any> {
    return this.http.get('Admin_Getlistform', {TypeformId, ...searchData});
  }

  AdminGetAllForm(): Observable<any> {
    return this.http.get('Admin-GetAllForm');
  }

  Admin_Useform(TypeformId, ControlId): Observable<any> {
    return this.http.post('Admin_Useform', {TypeformId, ControlId});
  }

  Get_Historyform(TypeformId, ControlId): Observable<any> {
    return this.http.get('Get_Historyform', {TypeformId, ControlId});
  }

  Admin_Deleteform(TypeformId, ControlId): Observable<any> {
    return this.http.post('Admin_Deleteform', {TypeformId, ControlId});
  }

  GetDataformByControlId(ControlId): Observable<any> {
    return this.http.get('Get-DataformByControlId', {ControlId});
  }

  Edit_PartcarsShow(RepairPartId: any): Observable<any> {
    return this.http.post('Edit_PartcarsShow', {RepairPartId});
  }

  Edit_PartcarsHide(RepairPartId: any): Observable<any> {
    return this.http.post('Edit_PartcarsHide', {RepairPartId});
  }

  POST_PartCars(RepairPartName: any): Observable<any> {
    return this.http.post('POST_PartCars', {RepairPartName});
  }

  Insert_ClPasrtCar(RepairPartName: any): Observable<any> {
    return this.http.post('asset/Insert_ClPasrtCar', {RepairPartName});
  }

  Update_Form1(TypeAssetId, TypeformId, DataForm): Observable<any> {
    const CreateBy = this.user.StaffId;
    return this.http.post('Update_Form1', {TypeAssetId, TypeformId, DataForm, CreateBy});
  }

  AdminClUpdateForm003(TypeAssetId, TypeformId, DataForm): Observable<any> {
    const CreateBy = this.user.StaffId;
    return this.http.post('Admin-ClUpdateForm003', {TypeAssetId, TypeformId, DataForm, CreateBy});
  }

  AdminCh_PostPDF(form_data, ControlId): Observable<any> {
    return this.http.post('Admin-Ch_PostPDF', {form_data, ControlId});
  }

  AdminRunningVersionForm(TypeformId, ControlId): Observable<any> {
    const CreateBy = this.user.StaffId;
    return this.http.post('Admin-RunningVersionForm', {TypeformId, ControlId, CreateBy});
  }

  AdminPostPDF001(form_data, ControlId): Observable<any> {
    return this.http.post('Admin-PostPDF001', {form_data, ControlId});
  }

  AdminRunningVersionForm003(TypeformId, ControlId): Observable<any> {
    const CreateBy = this.user.StaffId;
    return this.http.post('Admin-RunningVersionForm003', {TypeformId, ControlId, CreateBy});
  }

  AdminCl_PostPDF003(form_data, ControlId): Observable<any> {
    return this.http.post('Admin-Cl_PostPDF003', {form_data, ControlId});
  }

  AdminCh_GetPDF(ControlId): Observable<any> {
    const CreateBy = this.user.StaffId;
    return this.http.get('Admin-Ch_GetPDF', {ControlId});
  }

  AdminCl_GetPDF(ControlId): Observable<any> {
    const CreateBy = this.user.StaffId;
    return this.http.get('Admin-Cl_GetPDF', {ControlId});
  }

  AdminPDFForm(ControlId): Observable<any> {
    const CreateBy = this.user.StaffId;
    return this.http.get('asset/Admin-PDFForm', {ControlId});
  }

  ChGetDocWAppointment(searchData: any): Observable<any> {
    return this.http.get('asset1/Ch-GetDocWAppointment', searchData);
  }

  ChGetHistoryWork(searchData): Observable<any> {
    return this.http.get('asset1/Ch-GetHistoryWork', searchData);
  }

  ChGetDocWDetail(ContactId: any = ''): Observable<any> {
    return this.http.get('asset1/Ch-GetDocWDetail', {ContactId});
  }

  ChGetDocAppointment(searchData: any = {}): Observable<any> {
    return this.http.get('asset1/Ch-GetDocAppointment', searchData);
  }

  ChGetCalendar(AppointmentDate: any = '', AppointmentYear: any = ''): Observable<any> {
    return this.http.get('asset1/Ch-GetCalendar', {AppointmentDate, AppointmentYear});
  }

  ChGetTimeByDate(Date: any = ''): Observable<any> {
    return this.http.get('asset1/Ch-GetTimeByDate', {Date});
  }

  ChGetContactById(ContactId: any = ''): Observable<any> {
    return this.http.get('asset1/Ch-GetContactById', {ContactId});
  }

  ChGET_ShowRequestNo(ContactId: any = ''): Observable<any> {
    return this.http.get('asset1/Ch-GET_ShowRequestNo', {ContactId});
  }

  ChGetCDetail(ContactId: any = ''): Observable<any> {
    return this.http.get('asset1/Ch-GetCDetail', {ContactId});
  }

  ChInsertAppointment(data: any): Observable<any> {
    return this.http.post('asset1/Ch-InsertAppointment', data);
  }

  ChUpdateListAppointment(data: any): Observable<any> {
    return this.http.post('asset1/Ch-UpdateListAppointment', data);
  }

  ChUpdateStatusContact(ContactId: any = ''): Observable<any> {
    return this.http.post('asset1/Ch-UpdateStatusContact', {ContactId});
  }

  ChUpdateStatusProgress(ContactId: any = '', StaffId: any = ''): Observable<any> {
    return this.http.post('asset1/Ch-UpdateStatusProgress', {ContactId, StaffId});
  }

  ChGetTelContact(ContactId): Observable<any> {
    return this.http.get('asset1/Ch-GetTelContact', {ContactId});
  }

  ChGetRepairpartGroup(): Observable<any> {
    return this.http.get('asset1/Ch-GetRepairpartGroup');
  }

  ChGetDataFormUse(): Observable<any> {
    return this.http.get('asset1/Ch-GetDataFormUse');
  }

  ChDeleteImageCar(ImageCarId: any): Observable<any> {
    return this.http.post('asset1/Ch-DeleteImageCar', {ImageCarId});
  }

  ChInsertInspectionForm(ContactId, FormJson, StatusEdit, StatusFinish): Observable<any> {
    return this.http.post('asset1/Ch-InsertInspectionForm', {ContactId, FormJson, StatusEdit, StatusFinish});
  }

  ChUpdateInspectionForm(ContactId, InspectionCarId, FormJson, Statussend: any = ''): Observable<any> {
    return this.http.post('asset1/Ch-UpdateInspectionForm', {ContactId, InspectionCarId, FormJson, Statussend});
  }

  ChUpdate_EncypedInsId(InspectionCarId): Observable<any> {
    return this.http.post('asset1/Ch-Update_EncypedInsId', {InspectionCarId});
  }

  ChInsert_PDFInspection(InspectionCarId, form_data): Observable<any> {
    return this.http.post('asset1/Ch-Insert_PDFInspection', {InspectionCarId, form_data});
  }

  AdminGenPDFIns(form_data): Observable<any> {
    return this.http.post('asset1/Admin-GenPDFIns', {form_data});
  }

  ChUpdate_StatusFlow(FlowId, ContactId, StaffId = '') {
    return this.http.post('asset1/Ch-Update_StatusFlow=' + FlowId, {ContactId, StaffId});
  }

  ChUpdateStatusInsCar(ContactId) {
    return this.http.post('asset1/Ch-UpdateStatusInsCar', {ContactId});
  }

  ChInsertImageCar(data: any) {
    return this.http.post('asset1/Ch-InsertImageCar', data);
  }

  ChUpdateImageCar(data: any) {
    return this.http.post('asset1/Ch-UpdateImageCar', data);
  }

  ChGetInspectionFormById(InspectionCarId: any) {
    return this.http.get('asset1/Ch-GetInspectionFormById', {InspectionCarId});
  }

  ChGet_SignatureStaff(InspectionCarId: any) {
    return this.http.get('asset1/Ch-Get_SignatureStaff', {InspectionCarId});
  }

  ChGetDetailImageCarById(ImageCarId: any) {
    return this.http.get('asset1/Ch-GetDetailImageCarById', {ImageCarId});
  }

  ChUpdateStatusCall(ContactId: any, StatusContact = 2) {
    return this.http.post('asset1/Ch-UpdateStatusCall', {ContactId, StatusContact});
  }

  ChGet_PdfInspection(InspectionCarId) {
    return this.http.get('asset1/Ch-Get_PdfInspection', {InspectionCarId});
  }

  ChGetRemark(ContactId: any): Observable<any> {
    return this.http.get('asset1/Ch-GetRemark', {ContactId});
  }

  ClGetCarRegisterNo(CarRegisterNo, CarProvince): Observable<any> {
    return this.http.get('asset2/Cl-GetCarRegisterNo', {CarRegisterNo, CarProvince});
  }

  ClGetRunRequestNo(): Observable<any> {
    return this.http.get('asset2/Cl-GetRunRequestNo');
  }

  ClGetAllPrefix(): Observable<any> {
    return this.http.get('asset2/Cl-GetAllPrefix');
  }

  ClGetProvince(): Observable<any> {
    return this.http.get('asset2/Cl-GetProvince');
  }

  ClGetAllBrandCar(): Observable<any> {
    return this.http.get('asset2/Cl-GetAllBrandCar');
  }

  ClGetDistrict(ProvinceId): Observable<any> {
    return this.http.get('asset2/Cl-GetDistrict', {ProvinceId});
  }

  ClGetNameStaffArea(ProvinceId, DistrictId, AssignId = ''): Observable<any> {
    return this.http.get('asset2/Cl-GetNameStaffArea', {ProvinceId, DistrictId, AssignId});
  }

  ClInsertAssign(data): Observable<any> {
    return this.http.post('asset2/Cl-InsertAssign', data);
  }

  ClUpdateAssign(data): Observable<any> {
    return this.http.post('asset2/Cl-UpdateAssign', data);
  }

  ClGetEditAssign(AssignId): Observable<any> {
    return this.http.get('asset2/Cl-GetEditAssign', {AssignId});
  }

  ClALLWorkAssign(data): Observable<any> {
    return this.http.get('asset2/Cl-ALLWorkAssign', data);
  }

  ClGetALLMyWork(searchData, StaffId): Observable<any> {
    return this.http.get('asset2/Cl-GetALLMyWork', {...searchData, StaffId});
  }

  ClGetMyWorkById(AssignId): Observable<any> {
    return this.http.get('asset2/Cl-GetMyWorkById', {AssignId});
  }

  ClUpdateStatusWork(ContactId, StaffId): Observable<any> {
    return this.http.post('asset2/Cl-UpdateStatusWork', {ContactId, StaffId});
  }

  ClGetTypeForm(): Observable<any> {
    return this.http.get('asset2/Cl-GetTypeForm');
  }

  ClGetSide(): Observable<any> {
    return this.http.get('asset2/Cl-GetSide');
  }

  ClGetRepairPart(): Observable<any> {
    return this.http.get('asset2/Cl-GetRepairPart');
  }

  ClActiveDataform2(): Observable<any> {
    return this.http.get('asset2/Cl-ActiveDataform2');
  }

  ClGetDataCar(AssignId, Action: any = ''): Observable<any> {
    return this.http.get('asset2/Cl-GetDataCar', {AssignId, Action});
  }

  ClGetAllDamage(): Observable<any> {
    return this.http.get('asset2/Cl-GetAllDamage');
  }

  ClGenFormPDFClaim(form_data, Action: any = ''): Observable<any> {
    return this.http.post('asset2/Cl-GenFormPDF-Claim', {form_data, Action});
  }

  ACGenPDFclaim(form_data): Observable<any> {
    return this.http.post('asset2/AC-GenPDFclaim', {form_data});
  }

  ClInsertFormClaim(data): Observable<any> {
    return this.http.post('asset2/Cl-InsertFormClaim', data);
  }

  ClInsertFormClaim2(data): Observable<any> {
    return this.http.post('asset2/Cl-InsertFormClaim2', data);
  }

  ClUpdateFormClaim(data): Observable<any> {
    return this.http.post('asset2/Cl-UpdateFormClaim', data);
  }

  ClUpdateFormClaim2(data, Statussend: any = ''): Observable<any> {
    return this.http.post('asset2/Cl-UpdateFormClaim2', {...data, Statussend});
  }

  ClUpdate_EncypedClaimId(ClaimId): Observable<any> {
    return this.http.post('asset2/Cl-Update_EncypedClaimId', {ClaimId});
  }

  ClInsert_PDFCliam(TypeformId, TypeAssetId, form_data, FormJson, ClaimId): Observable<any> {
    return this.http.post('asset2/Cl-Insert_PDFCliam', {TypeformId, TypeAssetId, form_data, FormJson, ClaimId});
  }

  ClInsertImageCar(data): Observable<any> {
    return this.http.post('asset2/Cl-InsertImageCar', data);
  }

  ClDeleteImageCar(ImageCarId: any): Observable<any> {
    return this.http.post('asset2/Cl-DeleteImageCar', {ImageCarId});
  }

  ClUpdateImageCar(data): Observable<any> {
    return this.http.post('asset2/Cl-UpdateImageCar', data);
  }

  ClInsertFormAttachment(data): Observable<any> {
    return this.http.post('asset2/Cl-InsertFormAttachment', data);
  }

  ClUpdate_StatusFlow(StatusFlowId: any = '', ContactId): Observable<any> {
    let url = 'Cl-Update_StatusFlow';
    if (StatusFlowId) {
      url += '=' + StatusFlowId;
    }

    return this.http.post('asset2/' + url, {ContactId});
  }

  ClGET_DetailFormEn(ClaimId): Observable<any> {
    return this.http.get('asset2/Cl-GET_DetailFormEn', {ClaimId});
  }

  ClGet_PDFClaim(ClaimId: any): Observable<any> {
    return this.http.get('asset2/Cl-Get_PDFClaim', {ClaimId});
  }

  ClGET_DetailImage(ClaimId): Observable<any> {
    return this.http.get('asset2/Cl-GET_DetailImage', {ClaimId});
  }

  ClGET_Remark(ClaimId): Observable<any> {
    return this.http.get('asset2/Cl-GET_Remark', {ClaimId});
  }

  ClUpdateStatusFinish2(ClaimId, ContactId, StaffId): Observable<any> {
    return this.http.post('asset2/Cl-UpdateStatusFinish2', {ClaimId, ContactId, StaffId});
  }

  ACGetRunRequestNo(): Observable<any> {
    return this.http.get('asset2/AC-GetRunRequestNo');
  }

  ACPostReqNo(CarId, TelContact, RequestNo, StaffId): Observable<any> {
    return this.http.post('asset2/AC-PostReqNo', {CarId, TelContact, RequestNo, StaffId});
  }

  ACFormClaim(data): Observable<any> {
    return this.http.post('asset2/AC-FormClaim', data);
  }

  ACUpdateForm(data, Statussend: any = ''): Observable<any> {
    data.Statussend = Statussend;
    return this.http.post('asset2/AC-UpdateForm', data);
  }

  ACInsert_PDF1(form_data, FormJson, ClaimId): Observable<any> {
    return this.http.post('asset2/AC-Insert_PDF1', {form_data, FormJson, ClaimId});
  }

  ACPostImageCar(data): Observable<any> {
    return this.http.post('asset2/AC-PostImageCar', data);
  }

  CISMSForm(ClaimId, StatusFinish: any = ''): Observable<any> {
    return this.http.get('asset2/CI-SMSForm', {ClaimId, StatusFinish});
  }

  ACGetListMyJob(data, StaffId): Observable<any> {
    return this.http.get('asset2/AC-GetListMyJob', {...data, StaffId});
  }

  ACUpdateStatusFinish(ClaimId, ContactId, StaffId): Observable<any> {
    return this.http.post('asset2/AC-UpdateStatusFinish', {ClaimId, ContactId, StaffId});
  }

  ChInsertRegisterStaff(data): Observable<any> {
    return this.http.post('asset1/Ch-InsertRegisterStaff', data);
  }

  ChInsertDate(data): Observable<any> {
    return this.http.post('asset1/Ch-InsertDate', data);
  }

  ChUpdateStatusCT(ContactId): Observable<any> {
    return this.http.post('asset1/Ch-UpdateStatusCT', {ContactId});
  }

  GetRemarkStaffId(ContactId: any): Observable<any> {
    return this.http.get('asset/Get-RemarkStaffId', {ContactId});
  }

  GETDetailRemarkById(RemarkStaff: any): Observable<any> {
    return this.http.get('asset/GET-DetailRemarkById', {RemarkStaff});
  }

  InsertRemarkStaff(data: any): Observable<any> {
    return this.http.post('asset/Insert-RemarkStaff', data);
  }

  GetAllRemarkStaff(ContactId: any): Observable<any> {
    return this.http.get('asset/Get-AllRemarkStaff', {ContactId});
  }

  SentDocPDF(ContactId, ClaimId, StaffId): Observable<any> {
    return this.http.get('asset/SentDocPDF', {ContactId, ClaimId, StaffId});
  }

  ACCl_SendDocPDF(ContactId, ClaimId, StaffId): Observable<any> {
    return this.http.get('asset2/AC-Cl_SendDocPDF', {ContactId, ClaimId, StaffId});
  }

  InsSentDocPDF(ContactId, InspectionCarId, StaffId): Observable<any> {
    return this.http.get('asset/Ins-SentDocPDF', {ContactId, InspectionCarId, StaffId});
  }

  AdminNewDeleteResarea(ResAreaId): Observable<any> {
    return this.http.post('asset/Admin-NewDeleteResarea', {ResAreaId});
  }

  AdminGetphase(): Observable<any> {
    return this.http.get('asset/Admin-Getphase');
  }

  AdminPostPhaseCar(PhaseId, RepairpartGroupName): Observable<any> {
    return this.http.post('asset/Admin-PostPhaseCar', {PhaseId, RepairpartGroupName});
  }

  AdminCh_GenPDF(form_data): Observable<any> {
    return this.http.post('asset/Admin-Ch_GenPDF', {form_data});
  }

  AdminGetDateTime(DateAppointment) {
    return this.http.get('asset/Admin-GetDateTime', {DateAppointment});
  }

  AdminGetCalendar(SetDate) {
    return this.http.get('asset/Admin-GetCalendar', {SetDate});
  }

  AdminAllSetDate(data) {
    return this.http.get('asset/Admin-AllSetDate', data);
  }

  AdminOpenDate(SetDate) {
    return this.http.post('asset/Admin-OpenDate', {SetDate});
  }

  AdminCloseDate(SetDate) {
    return this.http.post('asset/Admin-CloseDate', {SetDate});
  }

  AdminUpdateRemark(SetDateId, Remark) {
    return this.http.post('asset/Admin-UpdateRemark', {SetDateId, Remark});
  }

  AdminSetDate(data) {
    data.CreateBy = this.user.StaffId;
    return this.http.post('asset/Admin-SetDate', data);
  }

  AdminAllSetTime(SetDate) {
    return this.http.get('asset/Admin-AllSetTime', {SetDate});
  }

  AdminFullTimeUse(DateAppointment) {
    return this.http.get('asset/Admin-FullTimeUse', {DateAppointment});
  }

  AdminSettingDate(DateAppointment) {
    return this.http.get('asset/Admin-SettingDate', {DateAppointment});
  }

  AdminControlDate(data) {
    return this.http.post('asset/Admin-ControlDate', data);
  }

  AdminInsertTime(data) {
    return this.http.post('asset/Admin-InsertTime', data);
  }

  AdminUpdateTime(data) {
    return this.http.post('asset/Admin-UpdateTime', data);
  }

  AdminEditTime(data) {
    return this.http.post('asset/Admin-EditTime', data);
  }

  Create_Call_Id(ContactId: any) {
    const StaffId = this.user.StaffId;
    return this.http.post('asset/Create_Call_Id', {ContactId, StaffId});
  }

  Insert_Video_Id(CallId: any, VideoName: any, VideoUserType: any) {
    return this.http.post('asset/Insert_Video_Id', {CallId, VideoName, VideoUserType});
  }

  GetConvertVideo(VideoName) {
    return this.http.get('asset/Get-ConvertVideo', {VideoName});
  }

  Get_Call(ContactId: any) {
    return this.http.get('asset/Get_Call', {ContactId});
  }

  SealUrl = environment.SealUrl;

  SealLogin(user: any = '', password: any = '', token: any = '') {
    const requestOptions = {
      headers: new HttpHeaders({
        Authorization: 'my-request-token'
      }),
      withCredentials: true
    };
    return this.http.httpClient.post(this.SealUrl + 'SWILogin', {user, password, token}, requestOptions);
  }

  AdminUseTime(SetDateId) {
    return this.http.post('asset/Admin-UseTime', {SetDateId});
  }
}
