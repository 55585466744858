import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LoggerService} from './logger.service';

const logger = new LoggerService('HttpService');

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  API_SERVER_ADDRESS = environment.API_SERVER_ADDRESS;
  API_SERVER_ADDRESS1 = environment.API_SERVER_ADDRESS1;

  constructor(public httpClient: HttpClient) {}

  setFormData(data: any) {
    data = data ? data : {};
    const formData = new FormData();
    const key = Object.keys(data);
    key.forEach(item => {
      formData.append(item, data[item]);
    });
    return formData;
  }

  setParamString(data: any) {
    data = data ? data : {};
    let params = new HttpParams();
    const key = Object.keys(data);
    key.forEach(item => {
      params = params.append(item, data[item] === null || data[item] === undefined ? '' : data[item]);
    });
    return params;
  }

  get(url: string, data: any = {}): Observable<any> {
    if (!url.startsWith('http')) {
      if (url.includes('asset')) {
        url = `${this.API_SERVER_ADDRESS1 + url}`;
      } else {
        url = `${this.API_SERVER_ADDRESS + url}`;
      }
    }

    const params = this.setParamString(data);
    return this.httpClient.get(url, {params}).pipe(tap(async (res: any) => {}));
  }

  post(url: string, data: any = {}): Observable<any> {
    if (!url.startsWith('http')) {
      if (url.includes('asset')) {
        url = `${this.API_SERVER_ADDRESS1 + url}`;
      } else {
        url = `${this.API_SERVER_ADDRESS + url}`;
      }
    }

    const formData = data instanceof FormData ? data : this.setFormData(data);
    return this.httpClient.post(url, formData).pipe(tap(async (res: any) => {}));
  }

  put(url: string, data: any = {}): Observable<any> {
    if (!url.startsWith('http')) {
      if (url.includes('asset')) {
        url = `${this.API_SERVER_ADDRESS1 + url}`;
      } else {
        url = `${this.API_SERVER_ADDRESS + url}`;
      }
    }

    const formData = this.setFormData(data);
    return this.httpClient.put(url, formData).pipe(tap(async (res: any) => {}));
  }

  delete(url: string, data: any = {}): Observable<any> {
    if (!url.startsWith('http')) {
      if (url.includes('asset')) {
        url = `${this.API_SERVER_ADDRESS1 + url}`;
      } else {
        url = `${this.API_SERVER_ADDRESS + url}`;
      }
    }

    const params = this.setParamString(data);
    return this.httpClient.delete(url, {params}).pipe(tap(async (res: any) => {}));
  }
}
