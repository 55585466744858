export const environment = {
    production: false,
    API_SERVER_ADDRESS: 'https://uapi.clouddoctor.asia/uapi/asset/',
    API_SERVER_ADDRESS1: 'https://uapi.clouddoctor.asia/uapi/',
    DEFAULT_LANGUAGE: 'th',
    VideoUserType: 1,
    JANUS_SERVER: 'wss://the-one-janus.rksoft.me/ws',
    JANUS_REPLAY_URL: 'https://the-one-janus.rksoft.me/play/',
    //JANUS_SERVER: 'wss://janus.rksoft.me/ws',
    customerMode: false,
    SealUrl: 'https://theone-report.ecm.in.th/'
};
